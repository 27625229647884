'use client'

import { Badge, Cell, Divider, Image, Label } from '@vinted/web-ui'

import {
  AddMember24,
  Donate24,
  Filters24,
  Logout24,
  Receipt24,
  Settings24,
  Wallet24,
} from '@vinted/monochrome-icons'

import { useShouldShowDarkModeBanner } from '@marketplace-web/shared/dark-mode'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'

import AccountSwitch from 'components/Header/UserMenu/AccountSwitch'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import useTranslate from 'hooks/useTranslate'
import { useSession } from '@marketplace-web/shared/session'
import useAsset from 'hooks/useAsset'

import {
  DONATIONS_SETTINGS_URL,
  GO_TO_WALLET_URL,
  MEMBER_PROFILE_URL,
  MY_ORDERS_URL,
  PROFILE_SETTINGS_URL,
  REFERRALS_URL,
  ROOT_URL,
  USER_PERSONALIZATION_SIZES_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { OrderType } from 'constants/my-orders'
import { EMPTY_USER_IMAGE_NAME } from 'constants/images'
import { logoutUser } from 'data/api/authentication/requests'
import { donationsClickEvent } from '_libs/common/event-tracker/events'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { useCookie } from '@marketplace-web/shared/cookies'

import AccountLinkWithIcon from './AccountLinkWithIcon'

const AccountLinksWithIcons = () => {
  const translate = useTranslate()
  const { track } = useTracking()
  const cookies = useCookie()
  const { user } = useSession()
  const asset = useAsset('assets/no-photo')
  const showFundraiser = !user?.business

  // TODO: 'payments' is a "special" (hard-coded) feature switch, which must be removed
  const isPaymentsEnabled = useFeatureSwitch('payments')
  const shouldShowMyOrdersNewBadge = useFeatureSwitch('my_orders_new_badge_web')
  const isReferralsEnabled = useFeatureSwitch('referrals')
  const shouldShowDarkModeAdoption = useShouldShowDarkModeBanner()
  const isImpersonatingBusiness = user?.impersonating_business
  const myOrdersDynamicDefaultTabAbTest = useAbTest('my_orders_dynamic_default_tab')

  const handleLogoutClick = async () => {
    const response = await logoutUser({ cookies })

    if (response) navigateToPage(ROOT_URL)
  }

  const handleFundraiserClick = () => {
    track(
      donationsClickEvent({
        target: ClickableElement.EnterDonationsFromWebMenu,
      }),
    )
  }

  const renderSettingsSuffix = () => {
    if (!shouldShowDarkModeAdoption) return null

    return <Badge theme="primary" content={translate('common.new_badge')} />
  }

  if (!user) return null

  const photoUrl = user.photo?.url || asset(EMPTY_USER_IMAGE_NAME)

  return (
    <div data-testid="user-menu-with-icons">
      <Label styling={Label.Styling.Wide} text={translate('header.user_menu.account.title')} />
      <SeparatedList separator={<Divider />}>
        <Cell
          title={user.login}
          body={translate('header.user_menu.account.view_profile')}
          styling={Cell.Styling.Default}
          theme="transparent"
          prefix={
            <Image
              src={photoUrl}
              size={Image.Size.Large}
              styling={Image.Styling.Circle}
              alt={translate('common.a11y.alt.user_photo')}
            />
          }
          url={MEMBER_PROFILE_URL(user.id)}
        />
        {!isImpersonatingBusiness && (
          <AccountLinkWithIcon
            title={translate('header.user_menu.account.items.settings.title')}
            url={PROFILE_SETTINGS_URL}
            suffix={renderSettingsSuffix()}
            testId="settings-account-link"
            icon={Settings24}
          />
        )}
        {!isImpersonatingBusiness && (
          <AccountLinkWithIcon
            title={translate('header.user_menu.account.items.personalization.title')}
            url={USER_PERSONALIZATION_SIZES_URL}
            icon={Filters24}
          />
        )}
        {isPaymentsEnabled && !isImpersonatingBusiness && (
          <AccountLinkWithIcon
            title={translate('header.user_menu.account.items.wallet.title')}
            url={GO_TO_WALLET_URL}
            icon={Wallet24}
          />
        )}
        <AccountLinkWithIcon
          title={translate('header.user_menu.account.items.my_orders.title')}
          url={MY_ORDERS_URL({
            type: myOrdersDynamicDefaultTabAbTest?.variant === 'on' ? '' : OrderType.Sold,
            isDynamicTabMenuLink: !!myOrdersDynamicDefaultTabAbTest?.variant,
          })}
          suffix={
            shouldShowMyOrdersNewBadge && (
              <Badge theme="primary" content={translate('common.new_badge')} />
            )
          }
          testId="my-orders-account-link"
          icon={Receipt24}
        />
        {showFundraiser && !isImpersonatingBusiness && (
          <AccountLinkWithIcon
            title={translate('header.user_menu.account.items.donations.title')}
            url={DONATIONS_SETTINGS_URL}
            onClick={handleFundraiserClick}
            icon={Donate24}
          />
        )}
        {isReferralsEnabled && !isImpersonatingBusiness && (
          <AccountLinkWithIcon
            title={translate('header.user_menu.account.items.referrals.title')}
            url={REFERRALS_URL}
            icon={AddMember24}
          />
        )}
        {user?.manageable_accounts?.length && <AccountSwitch />}
        <AccountLinkWithIcon
          title={translate('header.user_menu.account.items.logout.title')}
          onClick={handleLogoutClick}
          icon={Logout24}
        />
      </SeparatedList>
    </div>
  )
}

export default AccountLinksWithIcons
