'use client'

import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Dialog, Tabs, offset, shift, useFloating } from '@vinted/web-ui'
import classNames from 'classnames'

import { useIsMounted } from '@marketplace-web/shared/react-helpers'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import useTranslate from 'hooks/useTranslate'

import { ABOUT_TAB_ID, OUR_PLATFORM_ID, ROOT_CATALOG_ID } from 'constants/catalog'
import { ITEM_UPLOAD_URL, MAIN_NAVIGATION_ABOUT_TAB_URL, OUR_PLATFORM_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { transformCatalogDtoList } from 'data/transformers/catalog'
import { clickEvent } from '_libs/common/event-tracker/events'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { TabItem } from 'types/components'
import { CatalogDto } from 'types/dtos'
import { CatalogModalContent, CatalogWithoutParentIdModel } from 'types/models'
import ElectronicsTab from 'components/ElectronicsTab'

import AboutPanel from './AboutPanel'
import CategoriesPanel from './CategoriesPanel'

type Props = {
  tree: Array<CatalogDto>
  impressumUrl: string | null
  isBusinessAccountLinksVisible: boolean
  isOurPlatformVisible: boolean
}

const CatalogNavigation = ({
  tree,
  impressumUrl,
  isBusinessAccountLinksVisible,
  isOurPlatformVisible,
}: Props) => {
  const translate = useTranslate('header.main_navigation')
  const { track } = useTracking()
  const [activeTabId, setActiveTabId] = useState<string | number>(ROOT_CATALOG_ID)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isMounted = useIsMounted()
  const [modalContent, setModalContent] = useState<CatalogModalContent>({
    catalogName: '',
    imageUrl: '',
    itemsRemaining: 0,
  })

  const { floaterRef, triggerRef, floaterStyle } = useFloating({
    middleware: [
      offset({ mainAxis: 15, crossAxis: -16 }), // adjusted offsets
      shift({ padding: 0, crossAxis: true }),
    ],
    isFloaterVisible: activeTabId !== ROOT_CATALOG_ID && isMounted(),
    shouldAutoUpdate: true,
    placement: 'bottom-start',
  })

  const transformedTree = useMemo(() => transformCatalogDtoList(tree), [tree])

  const openCatalogModal = ({ catalogName, itemsRemaining, imageUrl }: CatalogModalContent) => {
    setModalContent({
      catalogName,
      itemsRemaining,
      imageUrl,
    })
    setIsModalOpen(true)
  }

  const closeCatalogModel = () => {
    setModalContent({
      catalogName: '',
      imageUrl: '',
      itemsRemaining: 0,
    })
    setIsModalOpen(false)
  }

  const handleTabChange = ({ id }: TabItem, event: MouseEvent) => {
    event.nativeEvent.stopImmediatePropagation()

    if (id === activeTabId) {
      setActiveTabId(ROOT_CATALOG_ID)
    } else {
      setActiveTabId(id)
    }

    if (id === OUR_PLATFORM_ID) {
      navigateToPage(OUR_PLATFORM_URL)
    }
  }

  const handleTitleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
  }

  const renderTabTitle = (url: string, title: string, testId: string, id: string | number) => (
    <a
      className="nav-links"
      role="button"
      href={url}
      onClick={handleTitleClick}
      ref={id === activeTabId ? triggerRef : null}
      data-testid={testId}
      tabIndex={-1}
    >
      {title}
    </a>
  )

  const renderTabContent = (item: CatalogWithoutParentIdModel) => {
    const isActive = item.id === activeTabId

    if (!isMounted() || !isActive) {
      return null
    }

    return (
      <div
        key={item.id}
        id={`nav-menu-${item.id}`}
        aria-hidden={!isActive}
        data-testid={`category-panel-${item.id}`}
        className={classNames('u-padding-none', {
          'is-active': isActive,
        })}
        ref={floaterRef}
        style={floaterStyle}
      >
        <CategoriesPanel rootCategory={item} triggerCatalogModal={openCatalogModal} />
      </div>
    )
  }

  const renderAboutTabContent = (isAboutPanelActive: boolean) => {
    if (!isMounted || !isAboutPanelActive) {
      return null
    }

    return (
      <div
        id="nav-menu-about"
        aria-hidden={!isAboutPanelActive}
        data-testid="category-panel-about"
        className={classNames('u-padding-none', {
          'is-active': isAboutPanelActive,
        })}
      >
        <AboutPanel
          impressumUrl={impressumUrl}
          isBusinessAccountLinksVisible={isBusinessAccountLinksVisible}
        />
      </div>
    )
  }

  const getTabItems = () => {
    const newList: Array<TabItem> = []
    const isAboutPanelActive = activeTabId === ABOUT_TAB_ID

    transformedTree.forEach(item => {
      const isElectronicsTab = item.url.includes('electronics')
      const title = renderTabTitle(item.url, item.title, `nav-tab-${item.id}`, item.id)

      newList.push({
        id: item.id,
        title,
        aria: {
          'aria-expanded': activeTabId === item.id,
          'aria-controls': `nav-menu-${item.id}`,
          'aria-label': item.title,
        },
        contentExperimental: renderTabContent(item) || isElectronicsTab, // Needed to allow overflow
        children: isElectronicsTab && <ElectronicsTab title={title} />,
      })
    })

    newList.push({
      id: ABOUT_TAB_ID,
      title: renderTabTitle(
        MAIN_NAVIGATION_ABOUT_TAB_URL,
        translate('tabs.about'),
        'nav-tab-about',
        ABOUT_TAB_ID,
      ),
      aria: {
        'aria-expanded': isAboutPanelActive,
        'aria-controls': 'nav-menu-about',
        'aria-label': translate('tabs.about'),
      },
      contentExperimental: renderAboutTabContent(isAboutPanelActive),
    })

    if (isOurPlatformVisible) {
      newList.push({
        id: OUR_PLATFORM_ID,
        title: renderTabTitle(
          OUR_PLATFORM_URL,
          translate('tabs.platform'),
          'nav-tab-platform',
          OUR_PLATFORM_ID,
        ),
        aria: {
          'aria-label': translate('tabs.platform'),
        },
      })
    }

    return newList
  }

  const handleClick = useCallback(() => {
    setActiveTabId(ROOT_CATALOG_ID)
  }, [])

  useEffect(() => {
    if (activeTabId !== ROOT_CATALOG_ID) {
      document.addEventListener('click', handleClick)
    }

    return () => document.removeEventListener('click', handleClick)
  }, [activeTabId, handleClick])

  useEffect(() => {
    if (
      activeTabId === ROOT_CATALOG_ID ||
      activeTabId === ABOUT_TAB_ID ||
      activeTabId === OUR_PLATFORM_ID
    )
      return

    const trackingEvent = clickEvent({
      target: ClickableElement.CatalogTab,
      targetDetails: `${activeTabId}`,
    })

    track(trackingEvent)
  }, [activeTabId, track])

  const title = translate('catalogs.new.title', {
    name: modalContent.catalogName,
  })

  const description = translate(
    'catalogs.new.details',
    {
      count: modalContent.itemsRemaining,
    },
    { count: modalContent.itemsRemaining },
  )

  const imageUrl = modalContent.imageUrl || undefined

  return (
    <>
      <nav className="u-fit-content-width">
        <Tabs
          items={getTabItems()}
          activeItemId={activeTabId}
          onClick={handleTabChange}
          role="menu"
        />
      </nav>
      <Dialog
        show={isModalOpen}
        closeOnOverlay
        defaultCallback={closeCatalogModel}
        imageUrl={imageUrl}
        title={title}
        body={description}
        actions={[
          {
            text: translate('catalogs.new.actions.upload'),
            style: Dialog.ActionStyling.Filled,
            url: ITEM_UPLOAD_URL,
          },
          {
            text: translate('catalogs.new.actions.close'),
            style: Dialog.ActionStyling.Flat,
            callback: closeCatalogModel,
          },
        ]}
      />
    </>
  )
}

export default CatalogNavigation
