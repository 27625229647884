'use client'

import { ReactNode, useContext, useMemo } from 'react'
import classNames from 'classnames'

import Toggleable from 'components/Toggleable'
import { ToggleableContext } from 'components/Toggleable/ToggleableContext'
import { BREAKPOINT_VALUES_MAP } from '@marketplace-web/shared/breakpoints'
import { getBrowserWidth } from '@marketplace-web/shared/browser'

type Alignment = 'left' | 'right' | 'dynamic'

type Props = {
  children: ReactNode
  isWide?: boolean
  isRounded?: boolean
  noShadow?: boolean
  alignment?: Alignment
  forceVisibility?: boolean
}

const DESKTOP_MIN_WIDTH = BREAKPOINT_VALUES_MAP.desktops[0]
const DESKTOPS_ALIGN_MARGIN = 300
const PORTABLES_ALIGN_MARGIN = 80

const DropdownMenu = ({
  children,
  isWide,
  isRounded,
  forceVisibility,
  alignment = 'right',
  noShadow,
}: Props) => {
  const { isVisible, containerRef } = useContext(ToggleableContext)

  const isHidden = !forceVisibility && !isVisible

  const shouldAlignLeft = useMemo(() => {
    if (isHidden) return false

    if (alignment === 'dynamic' && containerRef?.current) {
      const distanceFromLeft = containerRef.current.getBoundingClientRect().x
      const isDesktop = getBrowserWidth() >= DESKTOP_MIN_WIDTH
      const margin = isDesktop ? DESKTOPS_ALIGN_MARGIN : PORTABLES_ALIGN_MARGIN // The margin makes it favour right alignment

      return distanceFromLeft + margin < getBrowserWidth() / 2
    }

    return alignment === 'left'
  }, [isHidden, alignment, containerRef])

  const classes = classNames('dropdown-menu', {
    'dropdown-menu--shadow': !noShadow,
    'dropdown-wide dropdown--fixed-width': isWide,
    'dropdown-menu--rounded': isRounded,
    'dropdown-menu--align-left': shouldAlignLeft,
  })

  if (isHidden) return null

  return <Toggleable.Menu>{() => <div className={classes}>{children}</div>}</Toggleable.Menu>
}

export default DropdownMenu
